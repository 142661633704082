import React from "react";
import Layout from "../components/layout/Layout";
import { Button } from "../components/Button";

export default ({ location }) => {
    return (
        <Layout title="Thanks for the Partnership registration!" location={location}>
            <main id="content">
                <section className="hero is-fullheight-with-navbar">
                    <div className="hero-body">
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-9 is-offset-2">
                                    <h1>Thank you for submitting the form. Our Partner Manager will get in touch with you shortly! </h1>
                                    <p>Have any questions? Reach out to <a href="mailto:partners@frends.com">partners@frends.com</a></p>
                                    <Button color="blue" type="outlined" to="/" text="go back home" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}